'use client'

import { ComponentAppTestimonialFragment } from '@generated/schema'
import Carousel from 'app/components/Carousel/Carousel'
import SvgAppLogo from 'app/components/Svg/SvgAppLogo'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useTranslation from 'app/i18n/client'
import { removeNullsAndUndefineds } from 'app/utils/functions/arrayfilters'
import Image from 'next/image'
import { useInView } from 'react-intersection-observer'
import { IComposeComponentProps } from '../types'
import styles from './ComponentAppTestimonial.module.scss'

export default function ComponentAppTestimonialClient({
  id,
  title,
  footerText,
  appStoreLogo,
  appStoreUrl,
  googlePlayLogo,
  googlePlayUrl,
  downloadTitle,
  topShape,
  bottomShape,
  testimonialsCollection,
  parentComponentId,
  positionInParentComponent,
}: ComponentAppTestimonialFragment & IComposeComponentProps) {
  const hasAppStore = appStoreLogo?.url && appStoreUrl
  const hasGooglePlay = googlePlayLogo?.url && googlePlayUrl

  const { t } = useTranslation()
  const { inView: componentInView, ref: componentRef } = useInView({
    threshold: 0.01,
    triggerOnce: true,
  })

  return (
    <div
      ref={componentRef}
      data-testid='app-testimonial'
      className={`p-sm-relative ${styles.componentAppTestimonial}
        ${topShape?.url ? styles.customMargin : 'mt-sm-6 mt-md-7'}`}
    >
      <TrackImpression
        unitName={id}
        componentId={id}
        unitLocation='inline'
        parentComponentId={parentComponentId}
        positionInParentComponent={positionInParentComponent}
      />
      {topShape?.url && (
        <div
          className={`p-sm-absolute d-sm-block ${styles.topShape}
            ${componentInView ? styles.slideUpTopShape : ''}`}
        >
          <Image src={topShape.url} alt='' fill className='of-sm-contain' />
        </div>
      )}

      <div
        className={`pb-sm-6 pb-md-7 bg-background-low-scarletTint ${styles.testimonialSection}`}
      >
        <div className='container'>
          {title && <h2 className='f-sm-6 f-md-9 text-center'>{title}</h2>}

          <div className='pt-sm-6 pt-md-7 pb-sm-5 pb-md-6 mx-sm-n4 mx-md-0'>
            <div className='grid'>
              <div className='grid-col-sm-12 grid-col-lg-8 grid-start-lg-3'>
                <Carousel
                  slides={removeNullsAndUndefineds(
                    testimonialsCollection?.items
                  ).map((testimonial) => (
                    <div
                      className={`d-sm-flex h-sm-full ${styles.carouselSlide}`}
                    >
                      <p className='f-sm-4 f-md-6 text-sm-center text-md-left'>
                        {testimonial.testimonial}
                      </p>
                      <p className='text-bold text-center mt-sm-5 mt-md-6'>{`—${testimonial.authorName}, ${testimonial.authorRole}`}</p>
                    </div>
                  ))}
                  fullWidthItems
                />
              </div>
            </div>
          </div>

          {footerText && (
            <div className='grid'>
              <div className='grid-col-sm-12 grid-col-lg-8 grid-start-lg-3'>
                <p className='f-sm-1 text-center'>{footerText}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='pt-sm-6 bg-background-high-peachTint'>
        <div className='container'>
          <div className='text-center mb-sm-5 mb-md-6 lh-reset'>
            <SvgAppLogo className={styles.logo} />
          </div>
          <h3 className='mt-sm-5 mt-md-6 f-sm-6 f-md-9 text-center'>
            {downloadTitle}
          </h3>
          {(hasAppStore || hasGooglePlay) && (
            <div
              className={`d-sm-flex mx-sm-n4 mx-md-0 flex-sm-justify-center mt-sm-4 mt-md-5 lh-reset ${styles.gap20}`}
            >
              {hasAppStore && (
                <TrackClick
                  as='a'
                  href={appStoreUrl!}
                  className='rounded-sm-2'
                  isEngagementEvent
                  trackProps={{
                    eventType: 'community_download',
                    unitName: id,
                    componentId: id,
                    unitLocation: 'inline',
                    platform: 'apple',
                    linkedContentUrl: appStoreLogo?.url!,
                    parentComponentId,
                    positionInParentComponent,
                  }}
                >
                  <Image
                    src={appStoreLogo?.url!}
                    alt={t('download-on-app-store')}
                    width={154}
                    height={52}
                    className='of-sm-contain'
                  />
                </TrackClick>
              )}

              {hasGooglePlay && (
                <TrackClick
                  as='a'
                  href={googlePlayUrl!}
                  className='rounded-sm-2'
                  isEngagementEvent
                  trackProps={{
                    eventType: 'community_download',
                    unitName: id,
                    componentId: id,
                    unitLocation: 'inline',
                    platform: 'android',
                    linkedContentUrl: googlePlayLogo?.url!,
                    parentComponentId,
                    positionInParentComponent,
                  }}
                >
                  <Image
                    src={googlePlayLogo?.url!}
                    alt={t('download-on-google-play')}
                    width={173}
                    height={52}
                    className='of-sm-contain'
                  />
                </TrackClick>
              )}
            </div>
          )}

          {bottomShape?.url && (
            <div
              className={`p-sm-relative d-sm-block ${styles.bottomShapeContainer}`}
            >
              <Image
                src={bottomShape.url}
                alt=''
                fill
                className='of-sm-contain'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
