import CarouselClient from './Carousel.client'
import CarouselFull from './variants/CarouselFull/CarouselFull.client'

interface ICarouselProps {
  id?: string
  slides: React.ReactNode[]
  className?: string
  fullWidthItems?: boolean
}

function Carousel({
  id = 'carousel',
  slides,
  className,
  fullWidthItems,
}: ICarouselProps) {
  if (fullWidthItems) {
    return (
      <CarouselFull id={id} slides={slides} className={className} />
    )
  }
  return (
    <CarouselClient id={id} slides={slides} className={className} />
  )
}

export default Carousel
