export function removeNullsAndUndefineds<T>(
  value: T[] | null | undefined
): NonNullable<T>[] {
  if (value === null || value === undefined) {
    return []
  }

  if (!Array.isArray(value)) {
    throw new Error(`${value} is not an array`)
  }

  return value.filter((item) => item !== null && item !== undefined) as NonNullable<T>[]
}

type NonNullableKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}

export function filterObjectsByNonNullableKeys<
  T extends Record<string, any> | null | undefined,
  K extends keyof NonNullable<T>
>(
  value: T[] | null | undefined,
  keys: K[]
): NonNullableKeys<NonNullable<T>, K>[] {
  const filtered = removeNullsAndUndefineds(value).filter((item) => {
    const keysToCheck = keys || (Object.keys(filtered[0]) as K[])
    return keysToCheck.every(
      (key) => item[key] !== null && item[key] !== undefined
    )
  })

  return filtered
}
