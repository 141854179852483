import { ISvgProps } from './Svg.interface'

export default function SvgMouse({ className }: ISvgProps) {
  return (
    <svg
      width='15'
      height='26'
      viewBox='0 0 15 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      data-testid='svg-mouse'
      aria-hidden='true'
    >
      <rect
        x='1'
        y='1'
        width='13'
        height='24'
        rx='6'
        stroke='currentColor'
        strokeWidth='2'
      />
      <rect x='6' y='4' width='3' height='7' rx='1.5' fill='currentColor' />
    </svg>
  )
}
