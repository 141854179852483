'use client'

import SvgMouse from 'app/components/Svg/SvgMouse'
import SvgRestart from 'app/components/Svg/SvgRestart'
import useTranslation from 'app/i18n/client'
import Image from 'next/image'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import MobileBar from '../AppInfoShapes/MobileBar'
import styles from './AppInfoImage.module.scss'

interface IAppInfoImageProps {
  sectionAlignment: string
  urls: string[]
  animation: string
  imageAlignment: string
}

const imageAlignmentMap = {
  Top: 'top-sm-7 top-md-8',
  Middle: 'top-sm-5 top-md-6',
  Bottom: 'top-sm-n4 top-md-n5',
}

export default function AppInfoImage({
  sectionAlignment,
  urls,
  animation,
  imageAlignment,
}: IAppInfoImageProps) {
  const { t } = useTranslation()
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [playAnimation, setPlayAnimation] = useState(false)
  const [showReplay, setShowReplay] = useState(false)
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 1 })

  return (
    <div
      ref={ref}
      className={`d-sm-flex flex-sm-justify-center p-sm-relative ${
        imageAlignment === 'Top' ? 'mb-sm-4 mb-md-5 mb-lg-0' : ''
      }`}
    >
      {/* background shape */}
      <div
        className={`${styles.background} ${imageAlignmentMap[imageAlignment]}`}
        style={{
          transform: sectionAlignment === 'Left' ? 'rotate(180deg)' : '',
        }}
      >
        <svg
          viewBox='0 0 579 438'
          fill='currentColor'
          xmlns='http://www.w3.org/2000/svg'
          aria-hidden='true'
        >
          <path d='M0 0H360C480.95 0 579 98.0496 579 219C579 339.95 480.95 438 360 438H0V0Z' />
        </svg>
      </div>

      {/* animated images */}
      <div
        className={`${styles.fadeInAnimation} ${
          inView && imagesLoaded ? styles.inView : ''
        } ${sectionAlignment === 'Left' ? 'ml-lg-4' : 'mr-lg-4'}`}
        onAnimationEnd={() => setPlayAnimation(true)}
      >
        <div className={`shadow bg-static-creamLite ${styles.imagesContainer}`}>
          <div
            className={`h-sm-full w-sm-full ${
              playAnimation && styles.playAnimation
            } ${animation === 'Scroll' ? styles.scrollAnimation : ''} ${
              animation === 'Slide' ? styles.slideAnimation : ''
            } ${animation === 'Dissolve' ? styles.dissolveAnimation : ''}`}
            onAnimationEnd={() => setShowReplay(true)}
          >
            {urls.map((url, index) => (
              <Image
                key={url}
                src={url}
                alt=''
                fill
                className={`${styles.image}`}
                onLoad={() => setImagesLoaded(true)}
                style={{ '--current-image': index } as React.CSSProperties}
              />
            ))}
          </div>
          {animation === 'Scroll' && <MobileBar />}
        </div>

        <div
          className='d-sm-flex flex-sm-justify-center f-sm-2'
          style={{ visibility: showReplay ? 'visible' : 'hidden' }}
        >
          {animation === 'Scroll' ? (
            <span className='d-sm-flex flex-sm-align-center mt-sm-4 text-bold'>
              <SvgMouse className='w-sm-4 mr-sm-2' />
              {t('scroll-to-read')}
            </span>
          ) : (
            <button
              type='button'
              onClick={() => {
                setShowReplay(false)
                setPlayAnimation(false)
                setTimeout(() => setPlayAnimation(true), 0)
              }}
              className='Btn-link d-sm-flex flex-sm-align-center mt-sm-4 text-bold'
            >
              <SvgRestart className='w-sm-4 mr-sm-2' />
              {t('play-again')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
