import { ISvgProps } from './Svg.interface'

export default function SvgAppLogo({ className }: ISvgProps) {
  return (
    <svg
      viewBox='0 0 224 58'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='svg-app-logo'
      className={className}
      aria-hidden='true'
    >
      <g clipPath='url(#clip0_4613_1723)'>
        <path d='M79.1867 41.5851V50.5805H77.0199V48.1949C76.2758 49.7707 74.4154 50.7994 72.4018 50.7994C69.4252 50.7994 67.543 49.1579 67.543 46.6409C67.543 43.9707 69.6441 42.3511 73.0147 42.3511H76.1664C76.6479 42.3511 77.0199 42.0447 77.0199 41.4975C77.0199 39.7247 75.5098 38.4334 73.343 38.4334C71.3294 38.4334 69.9286 39.659 69.6222 41.1911L67.543 40.7534C68.0683 38.3239 70.4977 36.573 73.3867 36.573C76.7792 36.5949 79.1867 38.6741 79.1867 41.5851ZM77.0199 44.3866V44.0364H73.146C71.0667 44.0364 69.7973 45.0213 69.7973 46.6409C69.7973 48.0636 70.9135 48.9609 72.6864 48.9609C75.3784 48.939 77.0199 47.2319 77.0199 44.3866Z' />
        <path d='M82.9951 36.8138H85.1838V39.1338C85.9936 37.6018 87.8102 36.595 89.78 36.595C94.0479 36.595 96.3898 39.681 96.3898 43.6863C96.3898 47.6916 93.9823 50.7776 89.78 50.7776C87.8102 50.7776 86.0155 49.7708 85.1838 48.2387V56.1399H82.9951V36.8138ZM94.1136 43.7082C94.1136 40.6003 92.3189 38.5648 89.5612 38.5648C86.8034 38.5648 85.0087 40.5784 85.0087 43.7082C85.0087 46.8161 86.8034 48.8516 89.5612 48.8516C92.3189 48.8516 94.1136 46.8161 94.1136 43.7082Z' />
        <path d='M99.082 36.8138H101.271V39.1338C102.081 37.6018 103.897 36.595 105.867 36.595C110.135 36.595 112.477 39.681 112.477 43.6863C112.477 47.6916 110.069 50.7776 105.867 50.7776C103.897 50.7776 102.102 49.7708 101.271 48.2387V56.1399H99.082V36.8138ZM110.201 43.7082C110.201 40.6003 108.406 38.5648 105.648 38.5648C102.89 38.5648 101.096 40.5784 101.096 43.7082C101.096 46.8161 102.89 48.8516 105.648 48.8516C108.406 48.8516 110.201 46.8161 110.201 43.7082Z' />
        <path d='M67.543 19.1509V7.15698H70.9573V19.1509C70.9573 21.4491 72.7958 23.3313 75.0501 23.3313C77.3264 23.3313 79.1649 21.4491 79.1649 19.1509V7.15698H82.5573V19.1509C82.5573 23.2 79.1649 26.5049 75.0501 26.5049C70.9354 26.5049 67.543 23.1781 67.543 19.1509Z' />
        <path d='M85.7305 12.5194H89.0573V14.5768C89.8233 13.1541 91.3991 12.3005 93.369 12.3005C96.455 12.3005 98.4686 14.533 98.4686 17.7722V26.2643H95.1418V18.4726C95.1418 16.5903 93.9599 15.2771 92.2527 15.2771C90.3924 15.2771 89.0573 16.7436 89.0573 18.779V26.2643H85.7305V12.5194Z' />
        <path d='M116.329 23.4627V26.2642H115.256C113.265 26.2642 112.148 25.4763 111.754 24.1631C110.857 25.7389 108.997 26.4831 107.443 26.4831C103.372 26.4831 100.942 23.594 100.942 19.3917C100.942 15.1895 103.372 12.3004 107.443 12.3004C109.172 12.3004 110.748 13.1102 111.492 14.4016V6.60986H114.819V22.4778C114.819 23.0687 115.169 23.4627 115.694 23.4627H116.329ZM111.711 19.3917C111.711 16.8529 110.244 15.1895 107.99 15.1895C105.736 15.1895 104.269 16.8529 104.269 19.3917C104.269 21.9306 105.736 23.594 107.99 23.594C110.244 23.594 111.711 21.9306 111.711 19.3917Z' />
        <path d='M131.234 20.0703H121.078C121.319 22.2809 122.742 23.7036 124.865 23.7036C126.594 23.7036 127.71 23.0032 128.06 21.69L131.19 22.3466C130.402 24.8854 127.994 26.5051 124.865 26.5051C120.356 26.5051 117.839 22.9594 117.839 19.3481C117.839 15.7149 120.071 12.3005 124.58 12.3005C129.198 12.3005 131.277 15.6492 131.277 18.9322C131.277 19.3919 131.255 19.8077 131.234 20.0703ZM127.929 17.8598C127.797 16.1088 126.594 14.7956 124.602 14.7956C122.72 14.7956 121.516 15.7805 121.166 17.8598H127.929Z' />
        <path d='M140.032 15.3208C137.866 15.3208 137.034 17.1593 137.034 19.7638V26.2642H133.707V12.5193H137.012V14.9487C137.712 13.1978 138.785 12.5193 140.558 12.5193H142.133V15.3208H140.032Z' />
        <path d='M143.009 22.3028L145.854 21.7119C146.007 23.0032 147.102 23.9005 148.699 23.9005C150.013 23.9005 150.91 23.222 150.91 22.3466C150.91 19.173 143.315 22.0402 143.315 16.3058C143.315 13.9639 145.394 12.3005 148.24 12.3005C151.173 12.3005 153.274 13.767 153.733 16.1526L150.866 16.7436C150.669 15.5398 149.619 14.73 148.349 14.73C147.058 14.73 146.248 15.299 146.248 16.1964C146.248 19.1949 153.93 15.9994 153.93 22.2371C153.93 24.8417 151.851 26.5051 148.634 26.5051C145.548 26.4832 143.403 24.8854 143.009 22.3028Z' />
        <path d='M161.131 8.36084V12.5193H164.961V15.3208H161.131V21.3397C161.131 22.7405 161.875 23.4408 163.123 23.4408H164.961V26.2861H162.751C159.752 26.2861 157.782 24.5352 157.782 21.471V15.3208H155.068V12.5193H157.782V8.36084H161.131Z' />
        <path d='M166.077 19.3919C166.077 15.299 169.076 12.3005 173.278 12.3005C177.458 12.3005 180.457 15.299 180.457 19.3919C180.457 23.4847 177.458 26.4832 173.278 26.4832C169.076 26.4832 166.077 23.4847 166.077 19.3919ZM177.086 19.3919C177.086 16.8968 175.554 15.1896 173.3 15.1896C171.002 15.1896 169.47 16.9186 169.47 19.3919C169.47 21.8651 171.002 23.5941 173.3 23.5941C175.554 23.5941 177.086 21.887 177.086 19.3919Z' />
        <path d='M181.989 19.3919C181.989 15.299 184.988 12.3005 189.19 12.3005C193.37 12.3005 196.369 15.299 196.369 19.3919C196.369 23.4847 193.392 26.4832 189.212 26.4832C185.01 26.4832 181.989 23.4847 181.989 19.3919ZM192.998 19.3919C192.998 16.8968 191.466 15.1896 189.212 15.1896C186.914 15.1896 185.382 16.9186 185.382 19.3919C185.382 21.8651 186.914 23.5941 189.212 23.5941C191.466 23.5941 192.998 21.887 192.998 19.3919Z' />
        <path d='M213.287 23.4627V26.2642H212.214C210.223 26.2642 209.106 25.4763 208.712 24.1631C207.815 25.7389 205.955 26.4831 204.401 26.4831C200.33 26.4831 197.9 23.594 197.9 19.3917C197.9 15.1895 200.33 12.3004 204.401 12.3004C206.13 12.3004 207.706 13.1102 208.45 14.4016V6.60986H211.777V22.4778C211.777 23.0687 212.127 23.4627 212.652 23.4627H213.287ZM208.691 19.3917C208.691 16.8529 207.224 15.1895 204.97 15.1895C202.737 15.1895 201.249 16.8529 201.249 19.3917C201.249 21.9306 202.715 23.594 204.97 23.594C207.202 23.594 208.691 21.9306 208.691 19.3917Z' />
        <path d='M216.548 7.11326H215.104V6.60986H218.54V7.11326H217.095V11.1185H216.548V7.11326Z' />
        <path d='M219.087 6.60986H219.809L221.297 10.3744L222.786 6.60986H223.508V11.1185H222.983V7.52911L221.56 11.1185H221.079L219.656 7.52911V11.1185H219.131V6.60986H219.087Z' />
        <path d='M50.7555 58H7.24453C3.23925 58 0 54.7608 0 50.7555V7.24453C0 3.23925 3.23925 0 7.24453 0H50.7555C54.7608 0 58 3.23925 58 7.24453V50.7555C58 54.7608 54.7608 58 50.7555 58Z' />
        <path
          d='M48.6101 27.3586C46.0493 23.8786 46.0493 23.8567 46.4652 19.8952C46.4652 19.8733 46.4652 19.8733 46.4652 19.8514C46.8373 16.262 45.5241 12.7163 42.9195 10.2212C41.4312 8.7767 39.6146 7.74803 37.601 7.24463V29.8975C37.601 34.5593 33.8365 38.3458 29.1527 38.3458C29.0871 38.3458 29.0433 38.3458 28.9776 38.3458H28.8682L28.6493 38.3239L28.518 38.302C28.4524 38.302 28.3867 38.2801 28.321 38.2801H28.2992C25.7384 38.0393 23.4622 36.6605 22.0395 34.5156L22.1052 34.4937C21.2078 33.1148 20.7263 31.5171 20.7263 29.8756V11.6439L20.201 11.5782H20.1573C13.7007 10.9435 7.96633 15.6492 7.33161 22.1058C7.00331 25.3012 8.03199 28.4748 10.1112 30.9042C12.278 33.8809 12.2561 34.0778 11.8622 37.8424V37.8861C11.2056 44.299 15.8675 50.0333 22.2803 50.7118C25.2788 51.0182 28.2773 50.1646 30.6629 48.3042C34.1429 45.7435 34.1648 45.7435 38.1263 46.1593H38.1701C44.5829 46.8159 50.3173 42.1541 50.9958 35.7412C51.3241 32.7209 50.4705 29.7224 48.6101 27.3586Z'
          className='fill-foreground-white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4613_1723'>
          <rect width='223.464' height='58' className='fill-foreground-white' />
        </clipPath>
      </defs>
    </svg>
  )
}
