import { ISvgProps } from './Svg.interface'

export default function SvgRestart({ className }: ISvgProps) {
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      data-testid='svg-restart'
      aria-hidden='true'
    >
      <path d='M16.33 7.98003H22V2.31003H19.9V4.45203C19.564 3.99003 19.186 3.57003 18.808 3.15003C17.884 2.22603 16.792 1.47003 15.49 0.924034C13.054 -0.167966 10.198 -0.251966 7.34201 0.924034C6.12401 1.42803 4.99001 2.18403 4.02401 3.15003C3.10001 4.07403 2.34401 5.16603 1.79801 6.46803C1.25201 7.72803 0.958008 9.11403 0.958008 10.542C0.958008 11.886 1.25201 13.23 1.79801 14.574C2.30201 15.792 3.05801 16.926 4.02401 17.892C5.03201 18.9 6.12401 19.656 7.34201 20.118C8.60201 20.664 9.98801 20.958 11.416 20.958C12.76 20.958 14.104 20.664 15.448 20.118C16.666 19.614 17.8 18.858 18.766 17.892C19.774 16.884 20.53 15.792 20.992 14.574L19.06 13.776C18.682 14.7 18.052 15.624 17.254 16.422C16.456 17.22 15.574 17.808 14.608 18.228C13.474 18.69 12.424 18.9 11.332 18.9C10.198 18.9 9.10601 18.69 8.05601 18.228C7.13201 17.85 6.20801 17.22 5.41001 16.422C4.61201 15.624 4.02401 14.742 3.60401 13.776C3.14201 12.642 2.93201 11.592 2.93201 10.5C2.93201 9.36603 3.14201 8.27403 3.60401 7.26603C4.06601 6.21603 4.65401 5.33403 5.41001 4.62003C6.20801 3.82203 7.09001 3.23403 8.05601 2.81403C9.19001 2.35203 10.24 2.14203 11.332 2.14203C12.466 2.14203 13.558 2.35203 14.566 2.81403C15.616 3.27603 16.498 3.86403 17.212 4.62003C17.59 4.99803 17.926 5.41803 18.22 5.83803H16.204V7.98003H16.33Z' />
    </svg>
  )
}
