/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */

'use client'

import { useEffect, useRef, useState } from 'react'
import SvgCaretLeft from 'app/components/Svg/SvgCaretLeft'
import SvgCaretRight from 'app/components/Svg/SvgCaretRight'
import styles from './CarouselFull.module.scss'

interface ICarouselFullProps {
  id: string
  slides: React.ReactNode[]
  className?: string
}

function CarouselFull({ id, slides, className }: ICarouselFullProps) {
  const uListRef = useRef<HTMLUListElement>(null)
  const [activePage, setActivePage] = useState(0)

  useEffect(() => {
    const ulElement = uListRef.current
    if (!ulElement) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const page = parseInt(
              entry.target.getAttribute('data-slide') || '0',
              10
            )
            setActivePage(page)
          }
        })
      },
      { root: ulElement, threshold: [1] }
    )

    ulElement.querySelectorAll('li[data-slide]').forEach((element) => {
      observer.observe(element)
    })

    return () => {
      observer?.disconnect()
      if (ulElement) {
        ulElement.scrollLeft = 0
      }
      setActivePage(0)
    }
  }, [])

  const moveToPage = (page: number) => {
    const ulElement = uListRef.current
    if (!ulElement) return
    const pageElement = ulElement.querySelector(`li[data-slide='${page}']`) as HTMLElement | null
    if (!pageElement) return

    ulElement.scrollTo({
      left: pageElement.offsetLeft,
      behavior: 'smooth',
    })
    setActivePage(page)
  }

  const focusFirstVisibleItem = (page: number) => {
    if (!uListRef.current) return
    setTimeout(() => {
      const focusableChild = uListRef.current
        ?.querySelector(`li[data-slide='${page}']`)
        ?.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ) as HTMLElement | null
      focusableChild?.focus()
    }, 400)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowLeft' && activePage > 0) {
      event.preventDefault()
      moveToPage(activePage - 1)
      focusFirstVisibleItem(activePage - 1)
    }
    if (event.key === 'ArrowRight' && activePage < slides.length - 1) {
      event.preventDefault()
      moveToPage(activePage + 1)
      focusFirstVisibleItem(activePage + 1)
    }
  }

  return (
    <div className='p-sm-relative' data-testid={id}>
      <div className={`w-sm-full d-sm-flex flex-sm-align-center ${className ?? ''}`}>
        <div className={`w-sm-5 w-md-6 mr-sm-4 mr-md-5 mr-lg-7 no-print ${styles.controlsButtonContainer}`}>
          <button
            type='button'
            className={`${styles.controlsButton} ${
              activePage < 1 ? 'd-sm-none' : 'd-sm-inline-flex'} 
              Btn-link fill-svg text-foreground-darkblue hover-text-foreground-darkblue`}
            aria-label='previous slide'
            data-testid={`${id}-previous`}
            onClick={() => moveToPage(activePage - 1)}
          >
            <SvgCaretLeft className='w-sm-4 w-md-6 fill-foreground-darkblue' />
          </button>
        </div>
        <ul
          ref={uListRef}
          onKeyDown={handleKeyPress}
          tabIndex={0}
          className={`${styles.slides} w-sm-full list-reset d-sm-flex flex-sm-nowrap flex-sm-align-stretch mr-sm-1`}
        >
          {slides.map((slide, index) => (
            <li
              className={`${styles.slide} col-sm-12`}
              data-slide={index}
              aria-hidden={activePage === index ? 'false' : 'true'}
              key={index}
            >
              {slide}
            </li>
          ))}
        </ul>
        <div className={`w-sm-5 w-md-6 ml-sm-4 ml-md-5 ml-lg-7 no-print ${styles.controlsButtonContainer}`}>
          <button
            type='button'
            className={`${styles.controlsButton} ${
              activePage === slides.length - 1 ? 'd-sm-none' : 'd-sm-inline-flex'}
              Btn-link fill-svg text-foreground-darkblue hover-text-foreground-darkblue`}
            aria-label='next slide'
            data-testid={`${id}-next`}
            onClick={() => moveToPage(activePage + 1)}
          >
            <SvgCaretRight className='w-sm-4 w-md-6 fill-foreground-darkblue' />
          </button>
        </div>
      </div>

      <div className={`d-sm-flex ${styles.controls} flex-sm-align-center no-print`}>
        {slides.length > 1 && (
          <ul className='list-reset d-sm-flex flex-sm-justify-center text-static-scarletLite no-print'>
            {slides.map((_, index) => (
              <li data-testid={`${id}-indicator-${index}`} key={index}>
                <button
                  type='button'
                  className={`mx-sm-1 h-sm-3 w-sm-3 d-sm-block Btn-link ${styles.carouselIndicator}`}
                  aria-label={`go to slide ${index + 1}`}
                  onClick={() => moveToPage(index)}
                  data-testid={`${id}-dot-${index}`}
                >
                  <span
                    className={`w-sm-2 h-sm-2 d-sm-block circle-sm ${
                      activePage === index ? 'bg-static-darkblue' : 'bg-static-scarletLite'}`
                    }
                  />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
export default CarouselFull
